import React from 'react';

import styled from 'styled-components';

import color from 'ui/theme/color';
import fontSize from 'ui/theme/fontSize';

type Variant =
  | 'primary'
  | 'inverted'
  | 'fill'
  | 'outlined'
  | 'naked'
  | 'lightFilled'
  | 'fillBg'
  | 'danger'
  | 'icon';
type Size = 'default' | 'small' | 'extraSmall';

type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> & {
  className?: string;
  type?: 'submit' | 'reset';
  children: React.ReactNode;
  onClick?: () => void;
  variant: Variant;
  size?: Size;
  bgColor?: string;
  color?: string;
};

const Button: React.FC<Props> = ({
  children,
  size = 'default',
  type,
  disabled = false,
  ...props
}) => {
  return (
    <$Button {...props} size={size} type={type || 'button'} disabled={disabled}>
      {children}
    </$Button>
  );
};

export default Button;

const $Button = styled.button<{
  variant: Variant;
  size: Size;
  bgColor?: string;
  color?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${(props) => (props.size === 'extraSmall' ? '4px' : '14px')};
  font-size: ${(props) =>
    ({
      default: fontSize.large,
      small: fontSize.small,
      extraSmall: fontSize.small,
    })[props.size]};
  color: ${(props) =>
    props.color ||
    {
      primary: color.darkBg,
      inverted: color.white,
      fill: color.white,
      fillBg: color.text,
      danger: color.white,
      lightFilled: color.darkBg,
      outlined: color.lightBlack,
      naked: color.lightBlack,
      icon: color.lightBlack,
    }[props.variant]};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(p) =>
    p.bgColor ||
    {
      primary: color.primary,
      inverted: color.primary,
      fill: color.lightBlack,
      fillBg: color.bgFill,
      danger: color.error,
      lightFilled: color.darkGray,
      outlined: 'transparent',
      naked: 'transparent',
      icon: color.lightBlack,
    }[p.variant]};
  border: ${(props) =>
    ['outlined', 'fillBg'].includes(props.variant)
      ? `1px solid ${color.lightBlack}`
      : 'none'};
  border-radius: ${(p) => (p.variant === 'icon' ? '50%' : '4px')};
  transition: 0.5s;

  :hover {
    opacity: ${(p) => (p.disabled ? 1 : 0.8)};
  }
`;
