const color = {
  white: '#FFFFFF',
  lightGray: '#F0F0F0',
  gray: '#E7EBEE',
  darkGray: '#D9D9D9',
  darkBlue: '#113A58',
  lightBlue: '#6D879A',
  lightGreen: '#ABCB71',
  green: '#91A966',
  primary: '#EACF87',
  brown: '#CEA963',
  darkBrown: '#986E1B',
  lightBlack: '#333333',
  lightBlackBgOpacity: 'rgba(51, 51, 51, 0.8)',
  bg: '#F3F3F3',
  secondaryBg: '#eee',
  bgFill: '#FFFFFF',
  darkBg: '#333',
  text: '#333',
  lightText: '#666',
  darkBgFill: '#5a5a5a',
  error: '#E53E3E',
} as const;

export default color;
