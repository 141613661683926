import React from 'react';

import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import color from 'ui/theme/color';

type Props = {
  cause: string;
  solution: string;
  onBackToTop: () => void;
};

const ErrorPage: React.FC<Props> = (props) => (
  <Container>
    <Title>エラーが発生しました</Title>
    <Detail>
      <p>原因: {props.cause}</p>
      <p>解決策: {props.solution}</p>
    </Detail>
    <ButtonContainer>
      <Button variant='fill' onClick={props.onBackToTop}>
        トップに戻る
      </Button>
    </ButtonContainer>
  </Container>
);

export default ErrorPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const Title = styled.h1`
  color: ${color.text};
`;

const Detail = styled.div`
  color: ${color.text};
  text-align: center;
`;

const ButtonContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;
