const fontSize = {
  extraExtraLarge: '28px',
  extraLarge: '24px',
  semiExtraLarge: '20px',
  large: '16px',
  semiLarge: '14px',
  medium: '13px',
  small: '12px',
  extraSmall: '10px',
  extraExtraSmall: '8px',
} as const;

export default fontSize;
