/**
 * ドメインエラー基底クラス
 * (システムとして正常に稼動しているエラー)
 */
export abstract class DomainError extends Error {
  constructor(inner?: Error) {
    super(inner?.message);
    if (inner?.stack) {
      this.stack = inner.stack;
    }
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * 404エラー
 */
export class ResourceNotFoundError extends DomainError {}

/**
 * 403エラー
 */
export class ResourceForbiddenError extends DomainError {}

/**
 * 409エラー
 */
export class ResourceConflictError extends DomainError {}

/**
 * 不正なURLのエラー
 */
export class InvalidUrlError extends DomainError {}

/**
 * 401エラー
 */
export class UnauthorizedError extends DomainError {}
