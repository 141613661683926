import React, { useCallback, useEffect } from 'react';

import { FallbackProps } from 'react-error-boundary';

import ErrorPage from 'components/pages/ErrorPage';
import { useAuth } from 'hooks/useAuth';
import {
  InvalidUrlError,
  ResourceConflictError,
  ResourceForbiddenError,
  ResourceNotFoundError,
  UnauthorizedError,
} from 'ui/errors';

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const auth = useAuth();

  const onBackToTop = useCallback(() => {
    resetErrorBoundary();
    location.href = '/';
  }, [resetErrorBoundary]);

  useEffect(() => {
    window.addEventListener('popstate', (_e) => {
      // ブラウザバックしてもエラーページがで続けてしまうので強制リロード
      window.location.reload();
    });
  }, []);

  useEffect(() => {
    if (error instanceof UnauthorizedError) {
      resetErrorBoundary();
      location.href = `/?login=true&loginRedirectTo=${encodeURIComponent(
        location.pathname + location.search
      )}`;
    }
  }, []);

  if (error instanceof ResourceNotFoundError) {
    return (
      <ErrorPage
        cause='ページが存在しません'
        solution='URLを確認してください'
        onBackToTop={onBackToTop}
      />
    );
  }
  if (error instanceof ResourceConflictError) {
    return (
      <ErrorPage
        cause='重複しています'
        solution='一覧からやりなおしてください'
        onBackToTop={onBackToTop}
      />
    );
  }
  if (error instanceof ResourceForbiddenError) {
    return (
      <ErrorPage
        cause='このページを表示する権限がありません'
        solution={
          auth.authenticated
            ? '正しい権限が付与されているかを確認してください'
            : 'ログインしてください'
        }
        onBackToTop={onBackToTop}
      />
    );
  }
  if (error instanceof InvalidUrlError) {
    return (
      <ErrorPage
        cause='URLが不正です'
        solution='URLが間違っていないか確認してください'
        onBackToTop={onBackToTop}
      />
    );
  }
  if (error instanceof UnauthorizedError) {
    return <></>;
  }

  return (
    <ErrorPage
      cause='予期せぬエラーが発生しました'
      solution='時間をおいて再度お試しください'
      onBackToTop={onBackToTop}
    />
  );
};

export default ErrorFallback;
