import { useMutation } from '@tanstack/react-query';

function useUpdate<TPayload, TResult, TContext>(
  call: (payload: TPayload) => Promise<TResult>,
  options: UseUpdateOptions<TPayload, TResult, TContext>
): UseUpdateResult<TPayload, TResult, TContext> {
  return useMutation({ mutationFn: call, ...options });
}

export default useUpdate;
