import React from 'react';

import { createRoot } from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import AppProvider from './AppProvider';

import AppErrorBoundary from 'utils/AppErrorBoundary/AppErrorBoundary';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <AppErrorBoundary>
        <AppProvider>
          <App />
        </AppProvider>
      </AppErrorBoundary>
    </React.StrictMode>
  );
}
