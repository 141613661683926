import React, { Suspense } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'ress';

import { ApiClientProvider, PublicApiClientProvider } from 'api/apiClient';
import { ProvideAuth } from 'hooks/useAuth';
import GlobalStyle from 'ui/theme/GlobalStyle';
import ScrollToTop from 'ui/utils/ScrollToTop';

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ApiClientProvider>
      <PublicApiClientProvider>
        <ProvideAuth>
          <Suspense>
            <GlobalStyle />
            <BrowserRouter>
              <ScrollToTop />
              {children}
              <ToastContainer
                newestOnTop
                hideProgressBar
                pauseOnHover
                pauseOnFocusLoss
                closeOnClick={false}
                autoClose={3000}
                position='bottom-left'
                closeButton
                theme='colored'
                icon={false}
              />
            </BrowserRouter>
          </Suspense>
        </ProvideAuth>
      </PublicApiClientProvider>
    </ApiClientProvider>
  );
};

export default AppProvider;
