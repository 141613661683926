import {
  UserShowOwnedUserCarsRequest,
  UserShowOwnedUserCarsResponse,
} from '@wb-quaero/camper-interface-public/api';

import { useApiClient } from 'api/apiClient';
import useFetch from 'api/utils/useFetch';

export const QUERY_KEY_USER_SHOW_OWNED_CARS = 'userShowOwnedCars';

const useShowOwnedCars: UseFetch<
  UserShowOwnedUserCarsRequest,
  UserShowOwnedUserCarsResponse
> = (payload, options) => {
  const api = useApiClient('userShowOwnedUserCars');

  const res = useFetch(
    [QUERY_KEY_USER_SHOW_OWNED_CARS, payload.offset],
    () => api(payload),
    {
      ...options,
      meta: { errorMessage: '車両の一覧取得に失敗しました' },
    }
  );

  return res;
};

export default useShowOwnedCars;
