import { createGlobalStyle } from 'styled-components';

import color from './color';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Noto Sans JP', sans-serif;
  }

  html, body {
    min-height: 100dvh;
  }

  a {
    color: ${color.text};
    text-decoration: none;
  }

  #root {
    min-height: 100dvh;
    color: ${color.text};
    background-color: ${color.bg};
  }
`;

export default GlobalStyle;
