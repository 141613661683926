import { QueryKey, useQuery } from '@tanstack/react-query';

import { useAuth } from 'hooks/useAuth';

function useFetch<TResult>(
  queryKey: QueryKey,
  call: () => Promise<TResult>,
  options?: UseFetchOptions<TResult>
): UseFetchResult<TResult> {
  const auth = useAuth();
  return useQuery({
    queryKey,
    queryFn: call,
    ...options,
    enabled: auth.authenticated && (options?.enabled ?? true),
  });
}

export default useFetch;
