import React, { lazy } from 'react';

import { CarRentalType } from '@wb-quaero/camper-interface-public/api';
import { Route, Routes } from 'react-router-dom';

const ArticleCategory = lazy(() => import('components/pages/ArticleCategory'));
const ArticleDetail = lazy(() => import('./components/pages/ArticleDetail'));
const Articles = lazy(() => import('./components/pages/Articles'));
const ArticleSearch = lazy(() => import('./components/pages/ArticleSearch'));
const ArticleTag = lazy(() => import('./components/pages/ArticleTag'));
const CarDetail = lazy(() => import('./components/pages/CarDetail'));
const CarInsurance = lazy(() => import('./components/pages/CarInsurance'));
const CarReserve = lazy(() => import('./components/pages/CarReserve'));
const Reviews = lazy(() => import('./components/pages/Reviews'));
const ReviewDetail = lazy(() => import('./components/pages/ReviewDetail'));
// const Commerce = lazy(() => import('./components/pages/Commerce'));
const Company = lazy(() => import('./components/pages/Company'));
const Faq = lazy(() => import('./components/pages/Faq'));
const ForBeginner = lazy(() => import('./components/pages/ForBeginner'));
const Guide = lazy(() => import('./components/pages/Guide'));
const PrivacyPolicy = lazy(() => import('./components/pages/PrivacyPolicy'));
const ResetPassword = lazy(() => import('./components/pages/ResetPassword'));
const SearchUserCar = lazy(() => import('./components/pages/SearchUserCar'));
const OwnerInterviews = lazy(
  () => import('./components/pages/OwnerInterviews')
);
const OwnerInterviewDetail = lazy(
  () => import('./components/pages/OwnerInterviewDetail')
);
const SpotReviews = lazy(() => import('./components/pages/SpotReviews'));
const CarReviews = lazy(() => import('./components/pages/CarReviews'));
const SearchSpot = lazy(() => import('./components/pages/SearchSpot'));
const Share = lazy(() => import('./components/pages/Share'));
const ShareContract = lazy(() => import('./components/pages/ShareContract'));
const SpotDetail = lazy(() => import('./components/pages/SpotDetail'));
const SpotReserve = lazy(() => import('./components/pages/SpotReserve'));
const Top = lazy(() => import('./components/pages/Top'));
const Contact = lazy(() => import('./components/pages/Contact'));
const NewsList = lazy(() => import('./components/pages/NewsList'));
const NewsDetail = lazy(() => import('./components/pages/NewsDetail'));
const WithHeaderAndFooterLayout = lazy(
  () => import('./components/templates/WithHeaderAndFooterLayout')
);
const MyPageLayout = lazy(() => import('./components/templates/MyPageLayout'));
const MyPageToCarOwner = lazy(
  () => import('./components/pages/MyPageToCarOwner')
);
const MyPageToFacilityProvider = lazy(
  () => import('./components/pages/MyPageToFacilityProvider')
);
const MyPageDrivingLicense = lazy(
  () => import('./components/pages/MyPageDrivingLicense')
);
const MyPageFavorites = lazy(
  () => import('./components/pages/MyPageFavorites')
);
const MyPageManageCars = lazy(
  () => import('./components/pages/MyPageManageCars')
);
const MyPageManageSpots = lazy(
  () => import('./components/pages/MyPageManageSpots')
);
const MyPageInquiries = lazy(
  () => import('./components/pages/MyPageInquiries')
);
const MyPageNotificationSetting = lazy(
  () => import('./components/pages/MyPageNotificationSetting')
);
const MyPageManageLocation = lazy(
  () => import('./components/pages/MyPageManageLocation')
);
const MyPageProfile = lazy(() => import('./components/pages/MyPageProfile'));
const MyPageReserves = lazy(() => import('./components/pages/MyPageReserves'));
const MyPageTop = lazy(() => import('./components/pages/MyPageTop'));
const MyPageCarReserves = lazy(
  () => import('./components/pages/MyPageCarReserves')
);
const MyPageCarInquiries = lazy(
  () => import('./components/pages/MyPageCarInquiries')
);
const MyPageCarRents = lazy(() => import('./components/pages/MyPageCarRents'));
const MyPageCreateCar = lazy(
  () => import('./components/pages/MyPageCreateCar')
);
const MyPageEditCar = lazy(() => import('./components/pages/MyPageEditCar'));
const MyPageCarSales = lazy(() => import('./components/pages/MyPageCarSales'));
const MyPageSpotSales = lazy(
  () => import('./components/pages/MyPageSpotSales')
);
const MyPageRent = lazy(() => import('./components/pages/MyPageRent'));
const MyPageReturn = lazy(() => import('./components/pages/MyPageReturn'));
const MyPageAddReview = lazy(
  () => import('./components/pages/MyPageAddReview')
);
const MyPageReserveDetail = lazy(
  () => import('./components/pages/MyPageReserveDetail')
);
const MyPageCarDashboard = lazy(
  () => import('./components/pages/MyPageCarDashboard')
);
const MyPageSpotDashboard = lazy(
  () => import('./components/pages/MyPageSpotDashboard')
);
const MyPageSpotInquiries = lazy(
  () => import('./components/pages/MyPageSpotInquiries')
);
const MyPageSpotReserves = lazy(
  () => import('./components/pages/MyPageSpotReserves')
);
const MyPageCreateSpot = lazy(
  () => import('./components/pages/MyPageCreateSpot')
);
const MyPageEditSpot = lazy(() => import('./components/pages/MyPageEditSpot'));
const MyPageBankAccount = lazy(
  () => import('./components/pages/MyPageBankAccount')
);
const Terms = lazy(() => import('./components/pages/TermsPage'));

const App: React.FC = () => (
  <Routes>
    <Route path='/' element={<WithHeaderAndFooterLayout />}>
      {/* トップページ */}
      <Route path='' element={<Top />} />
      {/* お知らせ一覧 */}
      <Route path='news' element={<NewsList />} />
      {/* お知らせ詳細 */}
      <Route path='news/:newsId' element={<NewsDetail />} />
      {/* 初めてキャンピングカーを運転される方へ */}
      <Route path='for-beginner' element={<ForBeginner />} />
      {/* 自動車保険について */}
      <Route path='car-insurance' element={<CarInsurance />} />
      {/* ご利用案内 */}
      <Route path='guide' element={<Guide />} />
      {/* よくあるご質問 */}
      <Route path='faq' element={<Faq />} />
      {/* 利用規約 */}
      <Route path='terms' element={<Terms />} />
      {/* 共同使用契約 */}
      <Route path='share-contract' element={<ShareContract />} />
      {/* プライバシーポリシー */}
      <Route path='privacy-policy' element={<PrivacyPolicy />} />
      {/* NOTE: 特定商取引法に基づく表示の文言がきたらコメントアウト解除 */}
      {/* 特定商取引法に基づく表示 */}
      {/* <Route path='commerce' element={<Commerce />} /> */}
      {/* 会社情報 */}
      <Route path='company' element={<Company />} />
      {/* お問い合わせ */}
      <Route path='contact' element={<Contact />} />
      {/* オーナーインタビュー一覧 */}
      <Route path='interview' element={<OwnerInterviews />} />
      {/* オーナーインタビュー詳細 */}
      <Route path='interview/:id' element={<OwnerInterviewDetail />} />
      <Route path='reset-password' element={<ResetPassword />} />
      {/* レビュー */}
      <Route path='review' element={<Reviews />} />
      {/* カーシェア検索 */}
      <Route
        path='search-rv'
        element={<SearchUserCar rentalType={CarRentalType.CarShare} />}
      />
      {/* レンタカー検索 */}
      <Route
        path='search-rental'
        element={<SearchUserCar rentalType={CarRentalType.RentalCar} />}
      />
      {/* スポット・アクティビティ検索 */}
      <Route path='search-spot' element={<SearchSpot />} />
      {/* 記事トップ */}
      <Route path='articles' element={<Articles />} />
      {/* ユーザー向け LP 系 */}
      <Route path='share' element={<Share />} />
      {/* レビュー一覧 */}
      <Route path='reviews' element={<Reviews />} />
      {/* レビュー詳細 */}
      <Route path='reviews/:reviewId' element={<ReviewDetail />} />
      {/* 記事一覧 by カテゴリー */}
      <Route path='articles/category/:name' element={<ArticleCategory />} />
      {/* 記事一覧 by タグ */}
      <Route path='articles/tag/:name' element={<ArticleTag />} />
      {/* 記事検索 */}
      <Route path='articles/search' element={<ArticleSearch />} />
      {/* 記事詳細 */}
      <Route path='article/:id' element={<ArticleDetail />} />
      {/* 車両詳細 */}
      <Route path='car/:carId' element={<CarDetail />} />
      {/* 車両料金確認 & 予約 */}
      <Route path='car/:carId/reserve' element={<CarReserve />} />
      {/* 車両のレビュー一覧 */}
      <Route path='car/:carId/reviews' element={<CarReviews />} />
      {/* スポット・アクティビティ詳細 */}
      <Route path='spot/:spotId' element={<SpotDetail />} />
      {/* スポット・アクティビティ料金確認 & 予約 */}
      <Route path='spot/:spotId/reserve' element={<SpotReserve />} />
      {/* スポットのレビュー一覧 */}
      <Route path='spot/:spotId/reviews' element={<SpotReviews />} />
    </Route>
    <Route path='mypage' element={<MyPageLayout />}>
      {/* マイページトップ */}
      <Route path='' element={<MyPageTop />} />
      {/* マイページ通知設定 */}
      <Route
        path='notification-setting'
        element={<MyPageNotificationSetting />}
      />
      {/* マイページプロフィール */}
      <Route path='profile' element={<MyPageProfile />} />
      {/* マイページ身分証 */}
      <Route path='driving-license' element={<MyPageDrivingLicense />} />
      {/* マイページお気に入り */}
      <Route path='favorites' element={<MyPageFavorites />} />
      {/* 予約一覧 */}
      <Route path='reserves' element={<MyPageReserves />} />
      {/* お問い合わせ一覧 */}
      <Route path='inquiries' element={<MyPageInquiries />} />
      {/* 車両オーナー向け */}
      <Route path='cars'>
        {/* オーナーになる */}
        <Route path='to-owner' element={<MyPageToCarOwner />} />
        {/* マイページ受渡場所管理 */}
        <Route path='manage-locations' element={<MyPageManageLocation />} />
        {/* マイページ車両一覧 */}
        <Route path='manage' element={<MyPageManageCars />} />
        {/* マイページ車両作成 */}
        <Route path='new' element={<MyPageCreateCar />} />
        {/* マイページ車両編集 */}
        <Route path='edit/:carId' element={<MyPageEditCar />} />
        {/* ダッシュボード */}
        <Route path='dashboard' element={<MyPageCarDashboard />} />
        {/* 予約・空状況整理 */}
        <Route path='reserves' element={<MyPageCarReserves />} />
        {/* お問い合わせ管理 */}
        <Route path='inquiries' element={<MyPageCarInquiries />} />
        {/* 貸渡・返車管理 */}
        <Route path='rents' element={<MyPageCarRents />} />
        {/* 口座情報管理 */}
        <Route path='bank-account' element={<MyPageBankAccount />} />
        {/* 売上管理 */}
        <Route path='sales' element={<MyPageCarSales />} />
      </Route>
      <Route path='spots'>
        {/* ダッシュボード */}
        <Route path='dashboard' element={<MyPageSpotDashboard />} />
        {/* マイページ施設オーナーになる */}
        <Route path='to-provider' element={<MyPageToFacilityProvider />} />
        {/* スポット一覧 */}
        <Route path='manage' element={<MyPageManageSpots />} />
        {/* お問い合わせ管理 */}
        <Route path='inquiries' element={<MyPageSpotInquiries />} />
        {/* 予約・空状況管理 */}
        <Route path='reserves' element={<MyPageSpotReserves />} />
        {/* マイページ車両作成 */}
        <Route path='new' element={<MyPageCreateSpot />} />
        {/* マイページ車両編集 */}
        <Route path='edit/:spotId' element={<MyPageEditSpot />} />
        {/* 口座情報管理 */}
        <Route path='bank-account' element={<MyPageBankAccount />} />
        {/* 売上管理 */}
        <Route path='sales' element={<MyPageSpotSales />} />
      </Route>
      <Route path='reserves'>
        {/* 予約詳細 */}
        <Route path=':reserveId' element={<MyPageReserveDetail />} />
        {/* 貸渡処理 */}
        <Route path=':reserveId/rent' element={<MyPageRent />} />
        {/* 返却処理 */}
        <Route path=':reserveId/return' element={<MyPageReturn />} />
        {/* レビュー投稿 */}
        <Route path=':reserveId/review' element={<MyPageAddReview />} />
      </Route>
    </Route>
  </Routes>
);

export default App;
